const GTM_ID = process.env.REACT_APP_GTM_ID || '$REACT_APP_GTM_ID';

if (GTM_ID && !GTM_ID.endsWith('REACT_APP_GTM_ID')) {
    (function (w: Window, d: Document) {
        const tagName = 'script';
        const l = 'dataLayer';

        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        const f = d.getElementsByTagName(tagName)[0];
        const j = d.createElement(tagName);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';

        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + GTM_ID + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document);
}

window.getRandomHash = (() => {
    let getRandomHash = () => {
        let chars = '1234567890abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ';

        '016loGIOQ'.split('').forEach(char => {
            chars = chars.replace(new RegExp(char, 'g'), '');
        });

        const result = Array(21)
            .fill('')
            .map(() => chars[Math.floor(Math.random() * chars.length)])
            .join('');

        getRandomHash = () => result;
        return result;
    };

    return () => getRandomHash();
})();

import('./bootstrap');
